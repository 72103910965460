import ReactHlsPlayer from 'react-hls-player';
import './App.css';

function App() {
   return (
      <ReactHlsPlayer
         src="https://mprod-cdn.toffeelive.com/toffee/fifa-free/match-1/master_850.m3u8"
         autoPlay={false}
         controls={true}
         width="100%"
         height="auto"
      />
   );
}

export default App;
